<template lang="pug">
  .filters.h-100
    .row.align-items-center.m-0
      .filter.col
        p.filter-title {{ $t("company_system.basic_settings.drop_off_fees.prefecture") }}
        AppDropdown.prefecture(
          :loading="prefecturesLoading"
          checkbox
          batch-select
          multiple
          close-on-select
          allow-empty
          :value="filters.prefectures"
          :items="translatedPrefectures"
          @select="applyFilter('prefectures', $event)"
        )
      .filter.col
        p.filter-title {{ $t("company_system.basic_settings.drop_off_fees.shop") }}
        AppDropdown.prefecture(
          :loading="shopsLoading"
          checkbox
          batch-select
          multiple
          close-on-select
          allow-empty
          :value="filters.shops"
          :items="shops"
          @select="applyFilter('shops', $event)"
        )
      .filter.col
        p.filter-title {{ $t("company_system.basic_settings.drop_off_fees.car_type") }}
        AppDropdown.prefecture(
          :loading="carTypesLoading"
          checkbox
          batch-select
          multiple
          close-on-select
          allow-empty
          :value="filters.carTypes"
          :items="translatedCarTypes"
          @select="applyFilter('carTypes', $event)"
        )
      .filter.col
        .search.w-100
          AppSearch.w-100(
            :value="filters.searchValue"
            @update="applyFilter('searchValue', $event)"
          )
</template>

<script>
  // misc
  import { map } from "lodash-es"
  import { extractTranslatedAttribute } from "@/helpers/common"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      prefecturesLoading: {
        type: Boolean,
        default: true
      },
      prefectures: {
        type: Array,
        default: () => new Array()
      },
      shopsLoading: {
        type: Boolean,
        default: true
      },
      shops: {
        type: Array,
        default: () => new Array()
      },
      carTypesLoading: {
        type: Boolean,
        default: true
      },
      carTypes: {
        type: Array,
        default: () => new Array()
      },
      filters: {
        type: Object,
        default: () => new Object()
      }
    },

    methods: {
      applyFilter(itemType, items) {
        const filters = { ...this.filters, [itemType]: items }
        this.$emit("change-filters", filters)
      }
    },

    computed: {
      translatedPrefectures() {
        return map(this.prefectures, prefecture => {
          return {
            id: prefecture.id,
            name: extractTranslatedAttribute(prefecture, "name"),
            rawName: prefecture.en_name.toLowerCase().replace(" prefecture")
          }
        })
      },

      translatedCarTypes() {
        return map(this.carTypes, carType => {
          return {
            id: carType.id,
            name: extractTranslatedAttribute(carType, "type_name")
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .filters
    padding-top: 20px
    margin-left: 10px
    margin-right: 10px

    .filter
      +filter-title
      .filter-title
        margin-bottom: 0
        margin-left: 0

      min-width: 210px
      max-width: 25%
      padding: 0

      ::v-deep
        .app-select
          padding-left: 0

      .search
        padding-top: 20px
</style>
